import styled, { css } from 'styled-components';

import { mediaDesktop, mediaTablet } from 'utils/styles/responsive';
import { colors } from 'utils/styles/theme';

export const Wrapper = styled.div`
  overflow: hidden;
  position: relative;
  width: 100%;
  /* height: 35vh; */
  display: flex;
  align-items: center;
  justify-content: center;

  img {
    position: absolute;
    width: 100%;
    height: 100%;
    object-fit: contain;
    border-radius: 5px;
  }
`;

export const Dots = styled.div`
  position: absolute;
  bottom: 1rem;
  left: 50%;
  transform: translateX(-50%);
  display: flex;
`;

export const Dot = styled.div<{ isActive: boolean }>`
  width: 3rem;
  height: 3rem;
  box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
  border-radius: 50%;
  background-color: ${colors.white};
  margin: 0 1rem;
  cursor: pointer;

  ${({ isActive }) =>
    isActive &&
    css`
      background-color: ${colors.gold};
    `}
`;
