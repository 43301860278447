import { useInView } from 'framer-motion';
import React, { useEffect, useRef } from 'react';

import * as Styled from './OfferSection.styles';

export interface OfferSectionProps {
  className?: string;
  data: OfferSectionData;
}

const defaultProps: Partial<OfferSectionProps> = {};

const OfferSection: React.FC<OfferSectionProps> = ({
  className,
  data,
}: OfferSectionProps) => {
  return (
    <Styled.Wrapper className={className}>
      <Styled.Title
        initial={{ opacity: 0 }}
        whileInView={{ opacity: 1 }}
        viewport={{ amount: 0.5 }}
      >
        {data.title}
      </Styled.Title>
      <Styled.Services>
        {data.servicesPackage.map(servicePackage => (
          <Styled.ServicePackage
            key={servicePackage.name}
            data={servicePackage}
          />
        ))}
      </Styled.Services>
      <Styled.ActionLink href="/cennik">
        Przejdź do
        <br />
        cennika
      </Styled.ActionLink>
    </Styled.Wrapper>
  );
};

OfferSection.defaultProps = defaultProps;

export default OfferSection;
