import styled, { css } from 'styled-components';

import CtaButton from 'components/CtaButton';
import { setTypography, setVh } from 'utils/styles/mixins';
import { mediaDesktop, mediaTablet } from 'utils/styles/responsive';
import { colors } from 'utils/styles/theme';

export const Wrapper = styled.div`
  position: relative;
  z-index: 10;
  background-color: ${colors.white};
  width: 100%;
  padding: 5vh 0;
`;

export const Content = styled.div`
  display: flex;
  flex-direction: column;
  padding: 5vh 2rem;
  min-height: ${setVh(80)};
  justify-content: center;
  margin: 0 0;

  ${mediaDesktop(css`
    flex-direction: row;
    justify-content: space-around;
    align-items: center;
    max-width: 1300px;
    margin: 0 auto;
  `)}
`;

export const Map = styled.div`
  display: none;
  width: 40vw;
  height: 55vh;
  margin-left: 8rem;

  ${mediaDesktop(css`
    display: block;
  `)}
`;

export const ContactInfo = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  text-align: center;
`;

export const Title = styled.div`
  ${setTypography('heading1')}
  margin: 0 0 5vh 0;
`;

export const Copy = styled.div`
  ${setTypography('body1')}
  margin: 0 0 1vh 0;
`;

export const Street = styled.div`
  ${setTypography('heading1')}
  color: ${colors.gold};
  font-weight: 400;
`;

export const Comment = styled.div`
  ${setTypography('paragraph')}
  font-size: 1.5rem;
`;

export const Phone = styled.a`
  ${setTypography('heading1')}
  color: ${colors.gold};
  font-weight: 400;
`;

export const CTAButton = styled(CtaButton)`
  margin-top: 1.5vh;
`;

export const Separator = styled.div`
  width: 7rem;
  height: 1px;
  margin: 5vh 0;
  background-color: ${colors.black};

  ${mediaTablet(css`
    margin: 4vh;
  `)}
`;
