import { motion } from 'framer-motion';
import styled, { css } from 'styled-components';

import SocialMediasComponent from 'components/SocialMedias';
import { setTypography } from 'utils/styles/mixins';
import { mediaDesktop } from 'utils/styles/responsive';
import { colors } from 'utils/styles/theme';

export const Wrapper = styled.div`
  position: relative;
  z-index: 10;
  display: flex;
  flex-direction: column;
  align-items: center;

  background-color: ${colors.black};
  color: ${colors.white};

  padding-bottom: 5vh;

  min-height: 60vh;
  align-items: center;
  justify-content: center;
`;

export const Title = styled(motion.div)`
  ${setTypography('heading2')}
  text-align: center;
  padding: 5vh 2rem 2.5vh 2rem;
  max-width: 50rem;

  ${mediaDesktop(css`
    max-width: 80rem;
  `)}
`;

export const Subtitle = styled(motion.div)`
  ${setTypography('body1')}
  text-align: center;
  padding: 0 2rem 2.5vh 2rem;
`;

export const Copy = styled(motion.div)`
  ${setTypography('body1')}
  font-size: 2rem;
  text-align: center;
  padding: 0 2rem 5vh 2rem;
  max-width: 75rem;
`;

export const SocialMedias = styled(SocialMediasComponent)``;
