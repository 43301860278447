import { motion } from 'framer-motion';
import styled, { css } from 'styled-components';

import { setTypography } from 'utils/styles/mixins';
import { mediaDesktop, mediaTablet } from 'utils/styles/responsive';

export const Thumbnail = styled.img`
  object-fit: cover;
  border-radius: 5px;
  width: 100%;
  height: 34rem;
  transition: all 250ms ease-in;
`;

export const Wrapper = styled(motion.div)`
  display: flex;
  flex-direction: column;
  align-items: center;
  cursor: pointer;

  padding: 1.5vh 2rem;
  ${mediaTablet(css`
    /* max-width: 70vw; */
    width: 70vw;
  `)}

  ${mediaDesktop(css`
    width: 50rem;
  `)}

  &:hover {
    ${Thumbnail} {
      transform: scale(1.05);
    }
  }
`;

export const Title = styled.div`
  padding-top: 1vh;
  ${setTypography('body1')}
  line-height: 1.1;
  font-weight: 700;
`;
