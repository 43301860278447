import { motion } from 'framer-motion';
import styled, { css } from 'styled-components';

import ImageCarousel from 'components/ImageCarousel';
// import ImagesComponent from 'components/Images';
import { setTypography } from 'utils/styles/mixins';
import { mediaDesktop, mediaTablet } from 'utils/styles/responsive';
import { colors } from 'utils/styles/theme';

export const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  position: relative;
  z-index: 10;
  background-color: ${colors.white};

  justify-content: center;
  padding: 5vh 2rem 10vh 2rem;

  ${mediaTablet(css`
    width: 100%;
    padding: 5vh 6rem 10vh 6rem;
    margin: auto;
  `)}
`;

export const Title = styled.div`
  ${setTypography('heading1')}
  text-align: center;
`;

export const Text = styled.div`
  ${mediaDesktop(css`
    width: 40%;
    margin-right: 10%;
  `)}
`;

export const Images = styled(ImageCarousel)`
  margin-top: 2vh;

  height: 35vh;

  ${mediaTablet(css`
    width: 80%;
    height: 45vh;
  `)}

  ${mediaDesktop(css`
    margin-top: 0;
    width: 45% !important;
    height: 50vh;
  `)}
`;

export const Sections = styled(motion.div)`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;

  margin: 4rem 0 0 0;

  img {
    object-fit: cover;
  }

  ${mediaDesktop(css`
    align-items: flex-start;
    flex-direction: row;
    margin: 8rem 0 0 0;
    > div {
      &:first-child {
        margin-right: 5%;
      }
    }

    &:nth-child(odd) {
      flex-direction: row-reverse;

      > div {
        &:first-child {
          margin-right: 0;
          margin-left: 5%;
        }
      }
    }
  `)}

  p {
    margin-bottom: 1vh;
  }
`;
