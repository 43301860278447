import { useInView } from 'framer-motion';
import React, { useRef } from 'react';

import * as Styled from './BreakSection.styles';

export interface BreakSectionProps {
  className?: string;
  socialMedias: SocialMedia[];
}

const defaultProps: Partial<BreakSectionProps> = {};

const BreakSection: React.FC<BreakSectionProps> = ({
  className,
  socialMedias,
}: BreakSectionProps) => {
  const titleRef = useRef();
  const subtitleRef = useRef();

  const titleInView = useInView(titleRef, { amount: 0.5 });
  const subtitleInView = useInView(subtitleRef, { amount: 0.5 });

  return (
    <Styled.Wrapper className={className}>
      {/* <Parallax> */}
      <Styled.Title ref={titleRef} animate={{ opacity: titleInView ? 1 : 0 }}>
        Zadbamy o zdrowie, komfort i wygląd twojego czworonoga
      </Styled.Title>
      {/* <Styled.Subtitle>
        Możesz być pewiem, że wszystkie zabiegi będą wykonywane bezstresowo i z
        zachowaniem wszyskich przepisów bezpieczeństwa. Poradzimy sobie z nawet
        najmniej lubiącymi kąpiel psami.
      </Styled.Subtitle> */}
      <Styled.Copy
        ref={subtitleRef}
        animate={{ opacity: subtitleInView ? 1 : 0 }}
      >
        Zachęcamy do zapoznania się z naszą pracą na naszych mediach
        społecznościowych
      </Styled.Copy>
      <Styled.SocialMedias socialMediaList={socialMedias} />
      {/* </Parallax> */}
    </Styled.Wrapper>
  );
};

BreakSection.defaultProps = defaultProps;

export default BreakSection;
