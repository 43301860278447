import { useInView } from 'framer-motion';
import React, { useRef } from 'react';

import ArticleThumbnail from 'components/ArticleThumbnail';

import * as Styled from './ArticlesSection.styles';

export interface ArticlesSectionProps {
  className?: string;
  data: ArticlesSectionData;
  articles: Article[];
}

const defaultProps: Partial<ArticlesSectionProps> = {};

const container = {
  hidden: { opacity: 0 },
  show: {
    opacity: 1,
    transition: {
      staggerChildren: 0.1,
    },
  },
};

const item = {
  hidden: { opacity: 0 },
  show: { opacity: 1 },
};

const ArticlesSection: React.FC<ArticlesSectionProps> = ({
  className,
  data,
  articles,
}: ArticlesSectionProps) => {
  const ref = useRef();
  const isInView = useInView(ref, { amount: 0.3 });

  return (
    <Styled.Wrapper className={className}>
      <Styled.Title>{data.title}</Styled.Title>
      <Styled.Content>
        <Styled.Articles
          ref={ref}
          animate={isInView ? 'show' : 'hidden'}
          initial="hidden"
          variants={container}
        >
          {articles.map(article => (
            <ArticleThumbnail
              variants={container}
              key={`article-${article.slug}`}
              article={article}
            />
          ))}
        </Styled.Articles>
        <Styled.ActionLink href="/artykuly">Czytaj więcej</Styled.ActionLink>
      </Styled.Content>
    </Styled.Wrapper>
  );
};

ArticlesSection.defaultProps = defaultProps;

export default ArticlesSection;
