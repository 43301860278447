import { AnimatePresence, motion } from 'framer-motion';
import { wrap } from 'popmotion';
import React, { useState } from 'react';

import * as Styled from './ImageCarousel.styles';

export interface ImageCarouselProps {
  className?: string;
  images: Media[];
}

const defaultProps: Partial<ImageCarouselProps> = {};

const variants = {
  enter: {
    opacity: 0,
  },
  center: {
    opacity: 1,
  },
  exit: { opacity: 0 },
};

const swipeConfidenceThreshold = 10000;
const swipePower = (offset: number, velocity: number) => {
  return Math.abs(offset) * velocity;
};

const ImageCarousel: React.FC<ImageCarouselProps> = ({
  className,
  images,
}: ImageCarouselProps) => {
  const [[page, direction], setPage] = useState([0, 0]);
  const imageIndex = wrap(0, images.length, page);

  const paginate = (newDirection: number) => {
    setPage([page + newDirection, newDirection]);
  };

  return (
    <Styled.Wrapper className={className}>
      <AnimatePresence initial={false} custom={direction}>
        <motion.img
          key={page}
          src={images[imageIndex].url}
          custom={direction}
          variants={variants}
          initial="enter"
          animate="center"
          exit="exit"
          transition={{
            x: { type: 'spring', stiffness: 300, damping: 30 },
            opacity: { duration: 0.2 },
          }}
          drag={images.length > 1 ? 'x' : false}
          dragConstraints={{ left: 0, right: 0 }}
          // dragElastic={1}
          onDragEnd={(e, { offset, velocity }) => {
            const swipe = swipePower(offset.x, velocity.x);

            if (swipe < -swipeConfidenceThreshold) {
              paginate(1);
            } else if (swipe > swipeConfidenceThreshold) {
              paginate(-1);
            }
          }}
        />
      </AnimatePresence>
      {images.length > 1 && (
        <Styled.Dots>
          {images.map((image, idx) => (
            <Styled.Dot
              onClick={() => setPage([idx, page - idx])}
              key={image.url}
              isActive={idx === imageIndex}
            />
          ))}
        </Styled.Dots>
      )}
    </Styled.Wrapper>
  );
};

ImageCarousel.defaultProps = defaultProps;

export default ImageCarousel;
