import { AnimatePresence, useScroll } from 'framer-motion';
import { GetStaticProps } from 'next';
import React from 'react';

import { queryCMS } from 'utils/cms/api';
import { landingPageQuery } from 'utils/cms/gql';
import { ISR_TIMEOUT } from 'utils/config';
import { pageMotionProps } from 'utils/styles/animations';

import * as Styled from './LandingPage.styles';

import AboutUs from 'sections/AboutUs';
import ArticlesSection from 'sections/ArticlesSection';
import BreakSection from 'sections/BreakSection';
import ContactSection from 'sections/ContactSection';
import LandingSection from 'sections/LandingSection';
import OfferSection from 'sections/OfferSection';

export interface LandingPageProps {
  landingSectionData: LandingPageQuery;
  offerSectionData: OfferSectionData;
  articlesSection: ArticlesSectionData;
  articles: Article[];
  socialMedias: SocialMedia[];
  contactPageData: ContactPageData;
  aboutSectionData: AboutUsData;
  common: CommonType;
}

const defaultProps: Partial<LandingPageProps> = {};

export const getStaticProps: GetStaticProps = async () => {
  const response = await queryCMS({
    query: landingPageQuery(),
  });

  return {
    props: {
      head: response.landingPage.ogTags,
      navItems: response.menuItems,
      landingSectionData: response.landingPage,
      offerSectionData: response.serviceSection,
      contactPageData: response.contactPage,
      socialMedias: response.socialMedias,
      articlesSection: response.articlesSection,
      aboutSectionData: response.aboutUs,
      articles: response.articles,
      footerData: response.footer,
      common: response.sharedComponent,
    },
    revalidate: ISR_TIMEOUT,
  };
};

const LandingPage: React.FC<LandingPageProps> = ({
  landingSectionData,
  offerSectionData,
  articlesSection,
  articles,
  socialMedias,
  contactPageData,
  aboutSectionData,
  common,
}) => {
  return (
    <Styled.Wrapper {...pageMotionProps}>
      <AnimatePresence initial={true}>
        <LandingSection
          data={{ ...landingSectionData, phone: contactPageData.phone }}
          common={common}
        />
        <OfferSection data={offerSectionData} />
        <ArticlesSection data={articlesSection} articles={articles} />
        <AboutUs data={aboutSectionData} />
        <BreakSection socialMedias={socialMedias} />
        <ContactSection data={contactPageData} common={common} />
      </AnimatePresence>
    </Styled.Wrapper>
  );
};

LandingPage.defaultProps = defaultProps;

export default LandingPage;
