import { useInView } from 'framer-motion';
import { useRouter } from 'next/router';
import React, { useRef } from 'react';

import * as Styled from './ArticleThumbnail.styles';

export interface ArticleThumbnailProps {
  className?: string;
  article: Article;
  variants?: any;
}

const defaultProps: Partial<ArticleThumbnailProps> = {};

const ArticleThumbnail: React.FC<ArticleThumbnailProps> = ({
  className,
  article,
  ...props
}: ArticleThumbnailProps) => {
  const { push } = useRouter();
  const ref = useRef();
  // const isInView = useInView(ref, { amount: 0.4 });

  return (
    <Styled.Wrapper
      ref={ref}
      {...props}
      // animate={{ opacity: isInView ? 1 : 0 }}
      onClick={() => push(`/artykuly/${article.slug}`)}
      className={className}
    >
      <Styled.Thumbnail src={article.thumbnail.url} />
      <Styled.Title>{article.title}</Styled.Title>
    </Styled.Wrapper>
  );
};

ArticleThumbnail.defaultProps = defaultProps;

export default ArticleThumbnail;
