import { motion } from 'framer-motion';
import styled, { css } from 'styled-components';

import ActionLinkComponent from 'components/ActionLink';
import { setTypography, setVh } from 'utils/styles/mixins';
import { mediaDesktop } from 'utils/styles/responsive';
import { colors } from 'utils/styles/theme';

export const Wrapper = styled.div`
  position: relative;
  z-index: 10;
  background-color: ${colors.white};
  display: flex;
  flex-direction: column;

  min-height: ${setVh(70)};
`;

export const Content = styled.div`
  flex: 1;
  display: flex;
  flex-direction: column;
  justify-content: center;
`;

export const Title = styled.div`
  ${setTypography('heading2')}
  text-align: center;
  padding: 5vh 0;
`;

export const Articles = styled(motion.div)`
  display: flex;
  flex-direction: column;
  align-items: center;

  ${mediaDesktop(css`
    flex-direction: row;
    justify-content: center;
    align-items: start;
    flex-wrap: wrap;
  `)}
`;

export const ActionLink = styled(ActionLinkComponent)`
  padding: 3vh 2rem 5vh 0rem;
`;
