import { motion } from 'framer-motion';
import styled, { css } from 'styled-components';

import CtaButton from 'components/CtaButton';
import { setTypography, setVh } from 'utils/styles/mixins';
import { mediaDesktop, mediaTablet } from 'utils/styles/responsive';
import { colors } from 'utils/styles/theme';

export const Wrapper = styled.div`
  position: relative;
  overflow: hidden;

  text-align: center;

  /* height: calc(100vh - 6.7rem); */
  height: calc(var(--vh, 1vh) * 100 - 6.7rem);
  background-color: white;

  ${mediaDesktop(css`
    /* height: calc(100vh - 12.2rem); */
    height: calc(var(--vh, 1vh) * 100 - 11rem);
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
  `)}
`;

export const fixedContent = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  overflow: hidden;
  width: 100%;

  text-align: center;

  /* height: calc(100vh - 6.7rem); */
  height: calc(var(--vh, 1vh) * 100 - 6.7rem);
  top: var(--vh, 1vh) * 6.7;

  ${mediaDesktop(css`
    /* height: calc(100vh - 12.2rem); */
    top: 11rem;
    height: calc(var(--vh, 1vh) * 100 - 11rem);
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
  `)}
`;

export const Title = styled.div`
  font-size: 4.6rem;
  margin: 3vh 0;
  font-weight: bold;

  ${mediaDesktop(css`
    font-size: 5.8rem;
  `)}
`;
export const Subtitle = styled.div`
  font-size: 3.4rem;

  ${mediaDesktop(css`
    font-size: 3.5rem;
  `)}
`;

export const Content = styled.div`
  position: relative;
  z-index: 2;
  margin: 0 2rem;

  display: flex;
  flex-direction: column;
  align-items: center;
`;

export const ImageWrapper = styled(motion.div)`
  position: absolute;
  top: 0;
  right: 0;
  left: 0;
  bottom: 0;
`;

export const ImageRight = styled.img`
  position: absolute;
  height: min(70rem, 55vh);
  bottom: 0;
  left: 50%;
  transform: translateX(-50%);

  ${mediaDesktop(css`
    left: 0;
    transform: translate(-50%, 20%);
    max-width: none;
    height: 105vh;
    width: auto;
  `)}
`;

export const ImageLeft = styled.img`
  display: none;
  position: absolute;
  bottom: 0;

  ${mediaDesktop(css`
    display: inline-block;
    right: 0%;
    transform: translate(50%, 20%);
    max-width: none;
    height: 105vh;
    width: auto;
  `)}
`;

export const Button = styled(CtaButton)`
  position: absolute;
  bottom: ${setVh(10)};
  left: 50%;
  transform: translateX(-50%);

  ${mediaDesktop(css`
    display: none;
    position: static;
    transform: translateX(0);
    margin: 5vh 0;
  `)}
`;

export const Phone = styled.a`
  display: flex;
  align-items: center;
  margin: auto;
  margin-top: 3vh;
  font-size: 6rem;
  font-weight: bold;
  color: ${colors.gold};

  ${mediaTablet(css`
    margin-top: 10rem;
  `)}

  svg {
    margin-right: 2rem;
  }
`;
