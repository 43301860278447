import React from 'react';
import ReactMarkdown from 'react-markdown';

import * as Styled from './AboutUs.styles';

export interface AboutUsProps {
  className?: string;
  data: AboutUsData;
}

const defaultProps: Partial<AboutUsProps> = {};

const AboutUs: React.FC<AboutUsProps> = ({ className, data }: AboutUsProps) => {
  return (
    <Styled.Wrapper className={className}>
      <Styled.Title>{data.title}</Styled.Title>
      {data.sections.map((section, idx) => (
        <Styled.Sections
          initial={{
            opacity: 0,
            // x: isMobileLayout() ? 0 : idx % 2 === 0 ? 100 : -100,
          }}
          whileInView={{ opacity: 1, x: 0 }}
          viewport={{ amount: 0.3 }}
          key={`section-about-us-${idx}`}
        >
          <Styled.Text>
            <ReactMarkdown>{section.content}</ReactMarkdown>
          </Styled.Text>
          <Styled.Images images={section.images} />
        </Styled.Sections>
      ))}
    </Styled.Wrapper>
  );
};

AboutUs.defaultProps = defaultProps;

export default AboutUs;
