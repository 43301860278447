import { motion } from 'framer-motion';
import styled, { css } from 'styled-components';

import ActionLinkComponent from 'components/ActionLink';
import ServicePackageComponent from 'components/ServicePackage';
import { setTypography, setVh } from 'utils/styles/mixins';
import { mediaDesktop } from 'utils/styles/responsive';
import { colors } from 'utils/styles/theme';

export const Wrapper = styled.div`
  position: relative;
  z-index: 10;
  background-color: ${colors.black};
  color: ${colors.white};
  padding: 0 2rem;
  /* min-height: ${setVh(88)}; */

  /* display: flex;
  flex-direction: column;
  align-items: center; */
`;

export const Title = styled(motion.div)`
  ${setTypography('heading2')}
  text-align: center;
  padding: 5vh 0;
  opacity: 0;
`;

export const ActionLink = styled(ActionLinkComponent)`
  padding: 3vh 0 5vh 0;
`;

export const ServicePackage = styled(ServicePackageComponent)`
  max-width: 40rem;
  width: 100%;
  margin-left: auto;
  margin-right: auto;

  ${mediaDesktop(css`
    margin: 2rem;
  `)}
`;

export const Services = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;

  ${mediaDesktop(css`
    flex-direction: row;
    align-items: stretch;
    justify-content: center;
    flex-wrap: wrap;
    margin: 2.5vh 0;
  `)}
`;
