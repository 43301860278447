import React from 'react';

import * as Styled from './ContactSection.styles';

export interface ContactSectionProps {
  className?: string;
  data: ContactPageData;
  common: CommonType;
}

const defaultProps: Partial<ContactSectionProps> = {};

const ContactSection: React.FC<ContactSectionProps> = ({
  className,
  data,
  common,
}: ContactSectionProps) => {
  return (
    <Styled.Wrapper className={className}>
      <Styled.Content>
        <Styled.ContactInfo>
          <Styled.Title>{data.title}</Styled.Title>
          <Styled.Copy>{data.addressCopy}</Styled.Copy>
          <Styled.Street>{data.address}</Styled.Street>
          <Styled.Comment>{data.addressComment}</Styled.Comment>
          <Styled.Separator />
          <Styled.Copy>{data.phoneCopy}</Styled.Copy>
          <Styled.Phone href={`tel:${data.phone}`}>{data.phone}</Styled.Phone>
          <Styled.Separator />
          <Styled.Copy>{data.onlineBookingCopy}</Styled.Copy>
          <Styled.CTAButton
            onClick={() => window.open(common.signInUrl, '_blank')}
          >
            {common.signInCopy}
          </Styled.CTAButton>
        </Styled.ContactInfo>
        <Styled.Map
          dangerouslySetInnerHTML={{ __html: data.mapEmbed }}
        ></Styled.Map>
      </Styled.Content>
    </Styled.Wrapper>
  );
};

ContactSection.defaultProps = defaultProps;

export default ContactSection;
