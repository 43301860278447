import React from 'react';

import Phone from 'components/svgs/Phone';

import * as Styled from './LandingSection.styles';

export interface LandingSectionProps {
  className?: string;
  data: LandingPageQuery & { phone: string };
  common: CommonType;
}

const defaultProps: Partial<LandingSectionProps> = {};

const LandingSection: React.FC<LandingSectionProps> = ({
  className,
  data,
  common,
}: LandingSectionProps) => {
  return (
    <Styled.Wrapper className={className}>
      <Styled.fixedContent>
        <Styled.Content>
          <Styled.Title>{data.landingSectionTitle}</Styled.Title>
          <Styled.Subtitle
            dangerouslySetInnerHTML={{ __html: data.landingSectionSubtitle }}
          ></Styled.Subtitle>
          <Styled.Phone href={`tel:${data.phone}`}>
            <Phone />
            {data.phone}
          </Styled.Phone>
        </Styled.Content>
        <Styled.ImageWrapper>
          <Styled.ImageLeft src={data.landingPageImages[0].url} />
        </Styled.ImageWrapper>
        <Styled.ImageWrapper>
          <Styled.ImageRight src={data.landingPageImages[1].url} />
        </Styled.ImageWrapper>
        <Styled.Button
          onClick={() => {
            window.open(common.signInUrl, '_blank');
          }}
        >
          {common.signInCopy}
        </Styled.Button>
      </Styled.fixedContent>
    </Styled.Wrapper>
  );
};

LandingSection.defaultProps = defaultProps;

export default LandingSection;
