import React from 'react';

import { colors } from 'utils/styles/theme';

export default function Phone() {
  return (
    <svg
      width="6rem"
      height="6rem"
      viewBox="0 0 18 18"
      version="1.1"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g
        id="Icons"
        stroke="none"
        strokeWidth="1"
        fill="none"
        fillRule="evenodd"
      >
        <g id="Rounded" transform="translate(-749.000000, -1263.000000)">
          <g id="Communication" transform="translate(100.000000, 1162.000000)">
            <g
              id="-Round-/-Communication-/-phone"
              transform="translate(646.000000, 98.000000)"
            >
              <g>
                <polygon id="Path" points="0 0 24 0 24 24 0 24"></polygon>
                <path
                  d="M19.23,15.26 L16.69,14.97 C16.08,14.9 15.48,15.11 15.05,15.54 L13.21,17.38 C10.38,15.94 8.06,13.63 6.62,10.79 L8.47,8.94 C8.9,8.51 9.11,7.91 9.04,7.3 L8.75,4.78 C8.63,3.77 7.78,3.01 6.76,3.01 L5.03,3.01 C3.9,3.01 2.96,3.95 3.03,5.08 C3.56,13.62 10.39,20.44 18.92,20.97 C20.05,21.04 20.99,20.1 20.99,18.97 L20.99,17.24 C21,16.23 20.24,15.38 19.23,15.26 Z"
                  id="🔹Icon-Color"
                  fill={colors.gold}
                ></path>
              </g>
            </g>
          </g>
        </g>
      </g>
    </svg>
  );
}
